@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&family=Playfair+Display:wght@700&display=swap");

$section-bg: #fdf8f4;
$logo-bg: #436bfa;
$shadow-bg: #dfdfdf;
$text-color: #000b38;
$backdrop-bg: #fcf0e6;

@mixin flex($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin profile-img {
  width: 320px;
  height: 380px;
  border-radius: 4px;

  @media only screen and (max-width: 1080px) {
    width: 280px;
    height: 332px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: 320px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  color: #ffffff;
  background: $logo-bg;
}

html {
  cursor: none !important;
}

body {
  height: 100vh;
  padding: 24px;
  font-family: "Crimson Text", serif;
  color: $text-color;
  font-size: 24px;
  line-height: normal;
  opacity: 0;

  @media only screen and (max-height: 768px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    height: auto;
    padding: 12px;
    font-size: 16px;
  }
}

main {
  height: 100%;
  background-color: $section-bg;
}

section {
  @include flex;
  height: 100%;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;

  @media only screen and (max-width: 1080px) {
    max-width: 900px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    max-width: none;
    padding: 24px;
    display: block;
  }

  &.is-tablet {
    @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: none;
      padding: 24px;
      display: block;
    }
  }
}

.logo {
  width: fit-content;
  padding: 4px 8px;
  background-color: $logo-bg;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 54px;
  box-shadow: 2px 4px 4px $shadow-bg;
  position: absolute;
  top: 0;
  left: 0;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    left: 24px;
  }

  &.is-tablet {
    @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
      left: 24px;
    }
  }

  & > img {
    height: auto;
    width: 100%;
  }
}

.container {
  display: grid;
  grid-template-columns: calc(100% - 410px) 410px;
  padding: 54px 0;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    display: block;
  }

  &.is-tablet {
    @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.about {
  padding-right: 72px;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0;
  }

  &.is-tablet {
    @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
      padding: 0;
    }
  }

  &__content {
    h1 {
      font-family: "Playfair Display", serif;
      font-size: 100px;
      line-height: normal;
      margin: 5px 0;
      overflow: hidden;

      @media only screen and (max-height: 768px) {
        font-size: 90px;
      }

      @media only screen and (max-width: 1080px) {
        font-size: 80px;
      }

      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 48px;
      }
    }

    .letter {
      display: inline-block;
    }

    #current-job {
      text-decoration: none;
      color: $text-color;

      &:hover {
        color: $logo-bg;
      }
    }
  }

  &__resume {
    margin-top: 35px;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-top: 24px;
    }

    button {
      background: none;
      border: 2px solid $text-color;
      border-radius: 25px;
      padding: 10px 24px;
      position: relative;
      z-index: 99999;

      &:hover {
        color: #ffffff;
        border-color: rgba($text-color, 0);
      }
    }
  }
}

.profile {
  justify-self: center;
  padding-top: 40px;
  padding-left: 40px;
  @include flex(center, flex-start);

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    padding: 0;
    padding-top: 40px;
  }

  &.is-tablet {
    @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
      padding: 0;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto auto;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      width: 100%;
      grid-template-columns: 5fr 1fr;
    }

    &.is-tablet {
      @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
        display: block;
        width: 100%;
      }
    }
  }

  &__img-wrapper {
    position: relative;

    &.is-tablet {
      @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
        padding: 33px;
      }
    }
  }

  &__img {
    @include profile-img;
    background-size: cover;
    background-position: 70% 0;
    position: relative;
    z-index: 1;
    background-image: url("../img/ashish.jpg");

    &.is-tablet {
      @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
        height: 600px;
      }
    }
  }

  &__backdrop {
    @include profile-img;
    background: $backdrop-bg;
    position: absolute;
    top: 33px;
    left: -33px;
    z-index: 0;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      left: -12px;
      top: 12px;
    }

    &.is-tablet {
      @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
        left: 0;
        top: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__social {
    @include flex;
    flex-direction: column;
    list-style: none;
    padding-left: 24px;
    padding-top: 30px;
    margin: 0;

    &.is-tablet {
      @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
        flex-direction: row;
        padding: 24px 0;
        justify-content: flex-start;
      }
    }
  }

  &__social-item {
    width: 24px;
    height: auto;
    margin-bottom: 28px;
    border-radius: 50%;
    filter: invert(8%) sepia(65%) saturate(2970%) hue-rotate(217deg)
      brightness(81%) contrast(112%);
    transition: filter 0.1s ease-in-out;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      width: 20px;
    }

    &.is-tablet {
      @media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    &--facebook:hover {
      filter: invert(63%) sepia(78%) saturate(7461%) hue-rotate(204deg)
        brightness(96%) contrast(92%);
    }

    &--twitter:hover {
      filter: invert(50%) sepia(31%) saturate(3001%) hue-rotate(176deg)
        brightness(97%) contrast(88%);
    }

    &--instagram:hover {
      filter: invert(35%) sepia(9%) saturate(1517%) hue-rotate(326deg)
        brightness(93%) contrast(94%);
    }

    &--github:hover {
      filter: none;
    }

    &--linkedin:hover {
      filter: invert(29%) sepia(39%) saturate(3714%) hue-rotate(181deg)
        brightness(92%) contrast(98%);
    }
  }

  &__divider {
    flex: 1;
    border: 1px solid $text-color;
  }
}

#cursor {
  background: $text-color;
  transition-property: width, height, border-radius, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.1s;
  pointer-events: none;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  position: absolute;
  left: -1000px;
  z-index: 1000;
  width: 24px;
  height: 24px;
  opacity: 0.5;
}

#cursor.is-locked {
  transition-property: width, height, left, top, border-radius;
}

#cursor.cursor--text {
  border-radius: 2px !important;
  background: $text-color;
  width: 3px !important;
}
